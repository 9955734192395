import React from 'react';
import PrivacyPolicyContent from 'components/privacy-policy';

export default function PrivacyPolicy() {
  return (
    <main>
      <PrivacyPolicyContent />
    </main>
  );
}
