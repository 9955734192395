import React from 'react';
import Container from 'container';
import Headline from 'headline';
import BackArrow from 'shared/back-arrow';

import s from './privacy-policy.module.scss';

export default function PrivacyPolicyComponent() {
  return (
    <Container className={s.root}>
      <BackArrow />
      <Headline h1 wild center primary className={s.title}>
      Privacy Policy
      </Headline>
      <p>
        Your privacy is very important to us. Accordingly, we have developed
        this policy in order for you to understand how we collect, use,
        communicate and make use of personal information. The following outlines
        our privacy policy.
      </p>
      <p>
        When accessing the https://www.laurieanne.com website, will learn certain
        information about you during your visit.
      </p>
      <p>
        Similar to other commercial websites, our website utilizes a standard
        technology called "cookies" (see explanation below) and server logs to
        collect information about how our site is used. Information gathered
        through cookies and server logs may include the date and time of visits,
        the pages viewed, time spent at our site, and the websites visited just
        before and just after our own, as well as your IP address.
      </p>
      <h2>A. How we protect your information:</h2>
      <p>
        In accordance with GDPR and other national and local guidelines, we
        implement a variety of security measures to maintain the safety of your
        personal information when you place an order or access your personal
        information.
      </p>
      <p>
        We use a secure server. All supplied sensitive/credit information is
        transmitted via Transport Layer Security (TLS) technology and then
        encrypted into our payment gateway provider’s database only to be
        accessible by those authorized with special access rights to such
        systems and are required to keep the information confidential.
      </p>
      <p>
        Furthermore, our databases are set up in such a way that parts of your
        data are separated, so each separate part has no relevance without the
        other parts. This is in strict accordance with GDPR guidelines.
      </p>
      <p>
        Our employees are trained to understand and comply with these controls
        and we communicate our Privacy Notice, practices, and guidelines to our
        employees. However, while we strive to protect your personal
        information, you must also take steps to protect your information. We
        urge you to take every precaution to protect your personal information
        while you are on the Internet.
      </p>
      <p>
        Services and websites we sponsor have security measures in place to
        protect the loss, misuse, and alteration of the information under our
        control. While we make every effort to ensure the integrity and security
        of our network and systems, we cannot guarantee that our security
        measures will prevent third-party “hackers” from illegally obtaining
        this information.
      </p>
      <p>
        Know above all else that we respect your privacy and value your
        security. We do not share, sell or trade any of the information that we
        receive from you when you register with our company. We strive for
        excellence in maintaining your privacy.
      </p>
      <p>
        Do you have more questions? You can contact our Customer Service Team at
        hello@laurie-anneking.com or call us at (510) 833-6317.
      </p>
      <h2>B. Use of Cookies</h2>
      <p>
        LA King LLC uses cookies in a manner to make your internet experience
        with us easier and does not contain any of your personal information.
        They are used for the following reasons:
      </p>
      <p>
        Access to programs: Cookies are associated with the access to our online
        portal that comes with program registration. They allow you to
        automatically log into the programs and portions of the site that
        require membership.
      </p>
      <p>
        Shopping Cart: These cookies allow to you put an item in your shopping
        cart and to continue shopping without it being removed.
      </p>
      <p>
        A cookie is a small text file that is placed on your hard disk by a Web
        page server but cannot be used to run programs or deliver viruses to
        your computer. Web beacons, or action tags, may be used to assist in
        delivering the cookie on the Site. Most Web browsers automatically
        accept cookies, but you are able to update your setting to decline
        cookies. If you choose to decline cookies, some of the features of the
        site will change and may be unavailable.
        {' '}
      </p>
      <p>
        To help LA King LLC serve you better, we use technological means to
        better understand and adapt to user behavior. To accomplish this, the
        Sites, as well as our online software and services, email messages, and
        advertisements may use cookies or related technology such as pixel tags,
        clear gifs, and web beacons. These technologies help us to know which
        parts of the Sites people have visited, and provide data to aid us in
        understanding the effectiveness of the software services, our
        advertisements, and web searches. These data are used to improve our
        software, products, services, communications, and advertisements. We
        generally consider such information collected through the use of cookies
        and other technologies as non-personal information, and treat it
        accordingly.
      </p>
      <p>
        We also use cookies and other technologies to remember personal
        information when you use the Sites, software, products, or services.
        These uses are intended to improve your personal experience. For
        example, we can provide better and more personal results if we
        ‘remember’ your preferred settings, your name, and such.
        {' '}
      </p>
      <p>
        LA King LLC may use cookies or other technologies in connection with
        certain advertising services to determine your experience with our
        content including whether an ad is likely to be of interest to you,
        whether or not you are served a particular ad, and the number of times
        you are served a particular ad. These technologies allow us to measure
        the effectiveness of our ad campaigns. You may have options for opting
        out of such targeted ads. You should contact your service provider or
        your mobile provider to inquire about how to do so.
        {' '}
      </p>
      <p>
        LA King LLC also uses specific technologies in connection with certain
        communications such as emails that may be used to track actions by
        recipients. For example, these communications may include one or more
        clickable text or graphics that include URLs that link to content on the
        Sites. However, these URLs, may pass through a web server that tracks
        the click before arriving at the linked content on the Sites. The
        tracked data can help LA King LLC determine the effectiveness of our
        communications to users and customers, or to gage interest in specific
        topics. If you prefer not to have your responses to such communications
        tracked in this manner, do not click links in the email messages. Such
        communications may also include pixel tags that can tell us whether e.g.
        an email has been opened. We may use this information to throttle the
        number of messages sent to users or customers, or to remove people who
        do not read or interact with our communications from one or more lists.
      </p>
      <p>
        Cookies and other technologies can generally be controlled on your
        devices. If you want to disable cookies and you should review your
        privacy or security settings. Please note that certain features of the
        Sites or related software, products, or services may not be available if
        you have cookies disabled on your device. We will typically try to warn
        you that a page or feature you are trying to reach will not function
        without cookies enabled. If you have your cookies on your device or
        browser disabled and are having difficulties you may wish to enable them
        to see if the problem is resolved.
      </p>
      <h2>C. IP Addresses</h2>
      <p>
        IP addresses are used by your computer every time you are connected to
        the Internet. Your IP address is a number that is used by computers on
        the network to identify your computer. IP addresses are automatically
        collected by our web server as part of demographic and profile data
        known as “traffic data” so that data (such as the Web pages you request)
        can be sent to you.
      </p>
      <p>
        As part of standard protocols, LA King LLC servers automatically gather
        certain information and store it in log files. This information may
        include date/time stamp of access, Internet Protocol (IP) address(es),
        device type, operating system, browser and version, language, log-in
        details, Internet Service Provider (ISP), referring page, exit page, and
        other data.
      </p>
      <p>
        These log files are useful for a number of purposes including helping LA
        King LLC to maintain security, measure relative usage, understand and
        analyze trends regarding users, server access, load, or server problems,
        more effectively and efficiently administer the Sites, as well as
        related software, products, services, or communications, and gather
        non-personal demographic information about our users. Consistent with
        the provisions of this Privacy Policy, LA King LLC may use the data in
        its Log Files in a variety ways useful to its business or to provide
        better experiences for its users and customers.
      </p>
      <h2>D. Social Media</h2>
      <p>
        LA King LLC may collect certain information from you if you interact
        with us via social media such as Facebook, Twitter, LinkedIn, Google+,
        Pinterest, Snapchat, Instagram, and other such services. For example,
        you may be given the option to register for webinars, take advantage of
        special offers, receive mailings, newsletters or the like, via your
        social media account. The information we receive or collect from such
        social media, and our ability to collect it, may depend on the social
        media site, its policies, and its requirements or its technology. We may
        collect the information you provide from these social media interactions
        and may use it for various purposes to improve our services or your
        experience, or to contact you regarding the reason you connected with us
        or with additional offers in the future. Of course, you have the
        opportunity to opt-out at any time provided we do not require a means of
        contacting you to fulfill your request.
      </p>
      <h2>E. Children</h2>
      <p>
        Protecting children's privacy is important to LA King LLC. It is our
        policy to comply with the Children's Online Privacy Protection Act of
        1998 and all other applicable laws. Therefore we restrict our website
        use to persons eighteen years or older. Therefore, we do not knowingly
        collect personal information from children under 18. If we learn that LA
        King LLC has inadvertently collected or received the personal
        information of a child under 18 we will take steps to delete the
        information.
      </p>
      <p>
        [PLEASE NOTE: You must be 18 or older to use the Sites, and to purchase
        the products, or services offered via the Sites without an adult (parent
        or guardian).]
      </p>
      <p>
        DUE TO THE AGE RESTRICTIONS FOR USE OF THIS WEBSITE, NO INFORMATION
        OBTAINED VIA THIS WEBSITE FALLS WITHIN THE CHILDREN'S ONLINE PRIVACY
        PROTECTION ACT (COPPA) AND IT IS NOT MONITORED AS DOING SO.
      </p>
      <p>
        Notwithstanding the foregoing, if we discover or form a reasonable
        belief that we have received any information from a child under 18 in
        violation of this policy, we will delete that information. If you
        believe LA King LLC has any information from a child under age 18,
        please contact us at the following email address:
        hello@laurie-AnneKing.com
      </p>
      <h2>F. Disclosure to Third Parties</h2>
      <p>
        At times LA King LLC may make certain personal information available to
        strategic partners that work with us to provide products and services,
        or that help us service our customers. Personal information will only be
        shared by LA King LLC to provide or improve the Sites, and our products,
        services, and advertising.
      </p>
      <h2>G. Service Providers</h2>
      <p>
        To provide its various products or services, LA King LLC works with
        third party providers, who may be located wherever LA King LLC operates,
        or in any other location. Such companies provide services to LA King
        LLC. Examples may include information processing, emailing services,
        server hosting, transaction processing and banking services, video
        hosting, product/service delivery, communications, managing and
        enhancing customer data, providing technical support and/or customer
        service, and conducting customer research or satisfaction surveys. At
        any given time, LA King LLC may work with one or more such provider. We
        will only provide personal information to these companies where required
        for them to provide their services, or to improve our products or
        services for our customers. These companies are contractually obligated
        to protect your information.
      </p>
      <h2>H. Personal Information Protection</h2>
      <p>
        LA King LLC uses commercially reasonable security measures to protect
        against unauthorized access to or unauthorized alteration, disclosure or
        destruction of data. We restrict access of personal information to our
        employees, contractors, and/or agents who need to know that information
        in order to process it on our behalf. These individuals are bound by
        confidentiality obligations and may be subject to discipline, including
        termination and criminal prosecution, if they fail to meet these
        obligations. LA King LLC and its third party providers use Secure
        Sockets Layer (SSL) encryption on all web pages where personal
        information, including financial information is transmitted.
      </p>
      <p>
        You are solely responsible for any personal information you choose to
        submit on non-secure sites including but not limited any discussion
        forums or public comment threads on the Sites, or any social media
        sites, groups, or threads related to the Sites or the related products
        or services.
      </p>
      <p>
        We do not share any of your contact information with any additional
        parties. All contact will come directly from us at LA King LLC. Your
        emails, purchases and registrations are confidential. We do not share
        those details with any third parties.
      </p>
      <h2>I. Email Information</h2>
      <p>
        If you choose to correspond with us through email, we may retain the
        content of your email messages together with your email address and our
        responses. We provide the same protections for these electronic
        communications that we employ in the maintenance of information received
        online, mail and telephone. This also applies when you register for our
        website, sign up through any of our forms using your email address or
        make a purchase on this site. For further information see the email
        policies below.
      </p>
      <h2>J. How Do We Use the Information That You Provide to Us?</h2>
      <p>
        When you sign up for a program or service you will be added to our email
        list. This enables you to receive both information relevant to your
        program/purchase. You will also receive information on our upcoming
        events and programs in fields relating to both personal and business
        growth, development and expansion.
      </p>
      <p>
        When you register for a product or program you will be asked to provide
        specific personal information including name, email, phone number and
        billing information. This information is used to process your
        registration and to complete our deliverables for the program. We never
        share, sell, or trade your information with any third parties.
        {' '}
      </p>
      <p>
        Broadly speaking, we use personal information for purposes of
        administering our business activities, providing customer service and
        making available other items and services to our customers and
        prospective customers.
      </p>
      <p>
        will not obtain personally-identifying information about you when you
        visit our site, unless you choose to provide such information to us, nor
        will such information be sold or otherwise transferred to unaffiliated
        third parties without the approval of the user at the time of
        collection.
      </p>
      <p>
        We may disclose information when legally compelled to do so, in other
        words, when we, in good faith, believe that the law requires it or for
        the protection of our legal rights.
      </p>
      <h2>K. Email Policies</h2>
      <p>
        We are committed to keeping your e-mail address confidential. We do not
        sell, rent, or lease our subscription lists to third parties, and we
        will not provide your personal information to any third party
        individual, government agency, or company at any time unless strictly
        compelled to do so by law.
      </p>
      <p>
        We will use your e-mail address solely to provide timely information
        about .
      </p>
      <p>
        We will maintain the information you send via e-mail in accordance with
        applicable federal law.
      </p>
      <h2>L. CAN-SPAM Compliance</h2>
      <p>
        In compliance with the CAN-SPAM Act, all e-mail sent from our
        organization will clearly state who the e-mail is from and provide clear
        information on how to contact the sender. In addition, all e-mail
        messages will also contain concise information on how to remove yourself
        from our mailing list so that you receive no further e-mail
        communication from us.
      </p>
      <h2>M. Choice/Opt-Out</h2>
      <p>
        If you wish to stop receiving emails from us, you may unsubscribe at any
        time by following the link provided at the bottom of our broadcast
        emails. In the event this process does not stop the delivery of emails,
        please contact our office at (510) 833-6317 or via email at
        hello@laurie-anneking.com
      </p>
      <h2>N. Use of External Links</h2>
      <p>
        https://www.laurieanne.com may contain links to many other websites. cannot
        guarantee the accuracy of information found at any linked site. Links to
        or from external sites not owned or controlled by do not constitute an
        endorsement by or any of its employees of the sponsors of these sites or
        the products or information presented therein.
      </p>
      <p>
        By accessing this web site, you are agreeing to be bound by these web
        site Terms and Conditions of Use, all applicable laws and regulations,
        and agree that you are responsible for compliance with any applicable
        local laws. If you do not agree with any of these terms, you are
        prohibited from using or accessing this site. The materials contained in
        this web site are protected by applicable copyright and trade mark law.
      </p>
      <h2>O. EUROPEAN UNION USERS: THE GDPR</h2>
      <p>
        On or before May 25th, 2018 LA King LLC will be a fully compliant
        Processor of Personal Data under the GDPR. If you have any questions
        about your ability to use our programs or services in the EU or with
        regard to your EU customer’s data, feel free to contact us at
        Hello@laurie-anneking.com to discuss, or request a copy of our Data
        Processing Addendum to our EULA.
        {' '}
      </p>
      <h2>P. Intellectual Property Rights</h2>
      <p>
        All copyrights, trademarks, patents and other intellectual property
        rights in and on our website and all content and software located on the
        site shall remain the sole property of or its licensors. The use of our
        trademarks, content and intellectual property is forbidden without the
        express written consent from .
      </p>
      <p>You must not:</p>
      <p>Republish material from our website without prior written consent.</p>
      <p>Sell or rent material from our website.</p>
      <p>
        Reproduce, duplicate, create derivative, copy or otherwise exploit
        material on our website for any purpose.
      </p>
      <p>
        Redistribute any content from our website, including onto another
        website.
      </p>
      <h2>Q. Acceptable Use</h2>
      <p>
        You agree to use our website only for lawful purposes, and in a way that
        does not infringe the rights of, restrict or inhibit anyone else"s use
        and enjoyment of the website. Prohibited behavior includes harassing or
        causing distress or inconvenience to any other user, transmitting
        obscene or offensive content or disrupting the normal flow of dialogue
        within our website.
      </p>
      <p>
        You must not use our website to send unsolicited commercial
        communications. You must not use the content on our website for any
        marketing related purpose without our express written consent.
      </p>
      <h2>R. Restricted Access</h2>
      <p>
        We may in the future need to restrict access to parts (or all) of our
        website and reserve full rights to do so. If, at any point, we provide
        you with a username and password for you to access restricted areas of
        our website, you must ensure that both your username and password are
        kept confidential.
      </p>
      <h2>S. Use of Testimonials</h2>
      <p>
        In accordance to with the FTC guidelines concerning the use of
        endorsements and testimonials in advertising, please be aware of the
        following:
      </p>
      <p>
        Testimonials that appear on this site are actually received via text,
        audio or video submission. They are individual experiences, reflecting
        real life experiences of those who have used our products and/or
        services in some way. They are individual results and results do vary.
        We do not claim that they are typical results. The testimonials are not
        necessarily representative of all of those who will use our products
        and/or services.
      </p>
      <p>
        The testimonials displayed in any form on this site (text, audio, video
        or other) are reproduced verbatim, except for correction of grammatical
        or typing errors. Some may have been shortened. In other words, not the
        whole message received by the testimonial writer is displayed when it
        seems too lengthy or not the whole statement seems relevant for the
        general public.
      </p>
      <p>
        is not responsible for any of the opinions or comments posted on
        https://www.laurieanne.com. is not a forum for testimonials, however provides
        testimonials as a means for customers to share their experiences with
        one another. To protect against abuse, all testimonials appear after
        they have been reviewed by management of . doe not share the opinions,
        views or commentary of any testimonials on https://www.laurieanne.com - the
        opinions are strictly the views of the testimonial source.
      </p>
      <p>
        The testimonials are never intended to make claims that our products
        and/or services can be used to diagnose, treat, cure, mitigate or
        prevent any disease. Any such claims, implicit or explicit, in any shape
        or form, have not been clinically tested or evaluated.
      </p>
      <h2>
        T. How Do We Protect Your Information and Secure Information
        Transmissions?
      </h2>
      <p>
        Email is not recognized as a secure medium of communication. For this
        reason, we request that you do not send private information to us by
        email. However, doing so is allowed, but at your own risk. Some of the
        information you may enter on our website may be transmitted securely via
        a secure medium known as Secure Sockets Layer, or SSL. Credit Card
        information and other sensitive information is never transmitted via
        email.
      </p>
      <p>
        may use software programs to create summary statistics, which are used
        for such purposes as assessing the number of visitors to the different
        sections of our site, what information is of most and least interest,
        determining technical design specifications, and identifying system
        performance or problem areas.
      </p>
      <p>
        For site security purposes and to ensure that this service remains
        available to all users, uses software programs to monitor network
        traffic to identify unauthorized attempts to upload or change
        information, or otherwise cause damage.
      </p>
      <h2>U. Disclaimer and Limitation of Liability</h2>
      <p>
        makes no representations, warranties, or assurances as to the accuracy,
        currency or completeness of the content contain on this website or any
        sites linked to this site.
      </p>
      <p>
        All the materials on this site are provided "as is" without any express
        or implied warranty of any kind, including warranties of
        merchantability, noninfringement of intellectual property or fitness for
        any particular purpose. In no event shall or its agents or associates be
        liable for any damages whatsoever (including, without limitation,
        damages for loss of profits, business interruption, loss of information,
        injury or death) arising out of the use of or inability to use the
        materials, even if has been advised of the possibility of such loss or
        damages.
      </p>
      <h2>V. Policy Changes</h2>
      <p>
        We reserve the right to amend this privacy policy at any time with or
        without notice. However, please be assured that if the privacy policy
        changes in the future, we will not use the personal information you have
        submitted to us under this privacy policy in a manner that is materially
        inconsistent with this privacy policy, without your prior consent.
      </p>
      <p>
        We are committed to conducting our business in accordance with these
        principles in order to ensure that the confidentiality of personal
        information is protected and maintained.
      </p>
      <h2>W. Contact</h2>
      <p>
        If you have any questions regarding this policy, or your dealings with
        our website, please contact us here: hello@laurie-Anneking.com
      </p>
    </Container>
  );
}
